import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ContactForm from "./contactForm"

const CaptionContact = () => (
  <Container>
        <Row>
            <Col xs={12} lg={4}>
                <div className="captionContact">
                    <h1>Contact<br />LifeSync <div className="seoxtra">at 1-800-358-2468 or 954-354-9800</div></h1>
                    <p>We're here to help!</p>
                </div>
            </Col>
            <Col xs={12} lg={8}>
                <ContactForm />
            </Col>
        </Row>
  </Container>
)


export default CaptionContact
