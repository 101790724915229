import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ContactDetails = () => (
  <div className="contactDetails">
    <Container>
        <Row className="phoneNumbers">
            <Col md={4} lg={4}>
                <h2><a href="tel:18003582468">1.800.358.2468</a></h2>
                <p>&gt; Toll Free (US)</p>
            </Col>
            <Col md={4} lg={4}> 
                <h2><a href="tel:9543459800">954.345.9800</a></h2>
                <p>&gt; Phone</p>
            </Col>
            <Col md={4} lg={4}>
                <h2><a href="tel:9543450507">954.345.0507</a></h2>
                <p>&gt; Fax</p>
            </Col>
        </Row>
        <Row className="addressDetails">
            <Col>
                <p><a href="https://www.google.com/maps/place/11711+NW+39th+St,+Coral+Springs,+FL+33065/@26.2794808,-80.2869467,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9100260d3a2a5:0x35d16db80f50b689!8m2!3d26.279476!4d-80.284758" target="_blank" rel="noopener noreferrer"><span className="address">11705 NW 39<sup>th</sup> Street  |  Coral Springs  |  FL  |  33065  |  USA</span></a><br />
                &gt; Address </p>
            </Col>
        </Row>
    </Container>
  </div>
)

export default ContactDetails
