import React from "react"

import LayoutContact from "../components/Contact/layoutContact"
import SEO from "../components/seo"

import ContactDetails from "../components/Contact/contactDetails"

const ContactPage = () => {

  return (
    <LayoutContact>
      <SEO 
        title="Contact LifeSync at 1-800-358-2468 or 954-345-9800" 
        description="Connect with our teams of patient monitoring and cable manufacturing experts today."
        ogimg="https://www.lifesync.com/static/hero-home-p-68887cab881dc915bb49debf1d67f892.jpg"
        link="https://www.lifesync.com/contact/"
      />
      <ContactDetails />
    </LayoutContact>
  )
}

export default ContactPage
