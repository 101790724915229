import React from "react"

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"

import $ from 'jquery'
import Cookies from 'js-cookie'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default class ContactForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contactMessage: '',
        contactCustomerId: '',
        contactBillingAddress: '',
        token: ''
    };

    this.submit = this.submit.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.phoneChange = this.phoneChange.bind(this);
    this.msgChange = this.msgChange.bind(this);
    this.cIdChange = this.cIdChange.bind(this);
    this.bAChange = this.bAChange.bind(this);
  }
  submit(e) {
    e.preventDefault();
    let formObject = $('#contactForm');
    let postDt = new Date();
    let loadDt = new Date();
    //let _sid = 'qq3eu25egawyabolsjpyzcr5';
    let _sid = Cookies.get('ASP.NET_SessionId');
    if (formObject.find('input[name="js"]').length === 0) {
        let str = '<input type="hidden" name="js" value="1" />';
        formObject.append(str);
    }
    if (formObject.find('input[name="ttp"]').length === 0) {
        let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
        formObject.append(str);
    }
    if (formObject.find('input[name="sid"]').length === 0) {
        let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
        formObject.append(str);
    } else {
        formObject.find('input[name="sid"]').val(_sid);
    }

    let post_data = formObject.serialize();

    console.log(post_data);

    this.setState({
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contactMessage: '',
        contactCustomerId: '',
        contactBillingAddress: '',
        token: ''
    });

    $.ajax({
        url: "https://metrix.meritmile.com/email.aspx?ajax=1",
        type: 'POST',
        data: post_data,
        cache: false,
        dataType: 'json',
        success: function(data) {
            // Success..
            let r = data[0];
            formObject.find('.form-group').hide();
            formObject.find('.btn').hide();
            formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
        },
        // Fail..
        error: function(result) {
          let r = result[0];

            formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
            formObject.find('.submit').attr('disabled', false).removeClass('disabled');
        }
    });
  }

  nameChange(e){
    this.setState({contactName: e.target.value})
  }

  emailChange(e){
    this.setState({contactEmail: e.target.value})
  }

  phoneChange(e){
    this.setState({contactPhone: e.target.value})
  }

  msgChange(e){
    this.setState({contactMessage: e.target.value})
  }

  cIdChange(e){
    this.setState({contactCustomerId: e.target.value})
  }

  bAChange(e){
    this.setState({contactBillingAddress: e.target.value})
  }
  
  render() {
    return (
      <div className="contactForm">
        <GoogleReCaptchaProvider 
          reCaptchaKey="6LfPnuMjAAAAAH2QUiik4vO75sDYGLJAyMToj2F0"
        >
          <Form id="contactForm" className="formhandler" onSubmit={this.submit} name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx">
            <div className="feedback"></div>
            <div className="fix"></div>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Name *" name="name" required value={this.state.contactName} onChange={this.nameChange} />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="phone" placeholder="Phone" name="phone" value={this.state.contactPhone} onChange={this.phoneChange} />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email *" name="email" required value={this.state.contactEmail} onChange={this.emailChange} />
            </Form.Group>
            <Form.Group controlId="customerid">
              <Form.Label>Customer Name or ID#</Form.Label>
              <Form.Control type="text" placeholder="Customer Name or ID#" name="customerid" value={this.state.contactCustomerId} onChange={this.cIdChange} />
            </Form.Group>
            <Form.Group controlId="billingaddress">
              <Form.Label>Billing Address</Form.Label>
              <Form.Control type="text" placeholder="Billing Address" name="billingaddress" value={this.state.contactBillingAddress} onChange={this.bAChange} />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>How can we help you?</Form.Label>
              <Form.Control as="textarea" rows="2" placeholder="How can we help you?" name="message" value={this.state.contactMessage} onChange={this.msgChange} />
            </Form.Group>
            <input type="hidden" name="referrer" value="https://www.lifesync.com/contact/" />
            <input type="hidden" name="auth" value="d2218747-a2e7-4d49-8093-6e2bbde4fb08" />
            <input type="text" className="d-none hu" id="human-check" name="human-check" />
            <Button variant="primary" type="submit" className="float-right">
              Submit
            </Button>
            <GoogleReCaptcha
              onVerify={token => {
                // this.setState(token)
              }}
            />
          </Form>
        </GoogleReCaptchaProvider>
      </div>
    )
  }
}
