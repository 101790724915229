import React from "react"

import MainNavBar from "../mainNavBar"
import CaptionContact from "./captionContact"

const HeaderContact = ({ siteTitleContact }) => (
  <header className="headerContact">
    <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
    <MainNavBar />
    <CaptionContact />
  </header>
)

export default HeaderContact
